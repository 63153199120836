.plan-card {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }
  
  .plan-card.hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }

  .plan-card.selected {
    background-color: #f0f8ff; /* Couleur de fond attrayante */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }