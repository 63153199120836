.App {
  text-align: center;
  font-size: calc(6px + 2svmin);
  color: white;
}
.a-no-decoration {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}
.App-MainLogo {
  width: 375px;
  height: 115px;
  padding-top: 5px;
}
.App-NavLogo {
  width: 230px;
  max-height: 115;
  padding: 0px;

}

.App-logoL2R {
  height: 20vmin;
  pointer-events: none;
}

.App-logoR2L {
  height: 20vmin;
  pointer-events: none;
}

.display-linebreak {
  white-space: pre-line;
}

.Signature {
 /* position: absolute;*/

  transform: translate(5%, 40%);
  height: 40px;
  width: 105px;
}

.Card-logo {
  position: absolute;

  transform: translate(0, -10%);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logoL2R {
    animation: Logo-spinL2R infinite 5s ease-in-out;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logoR2L {
    animation: Logo-spinR2L infinite 5s ease-in-out;
  }
}
.App-header {
  min-height: 21vmin;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Calendar {
  min-height: 21vmin;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  color: black;
  /*font-size: calc(8px + 2vmin);*/
}
/*
.fc-event {
    font-size: 0.5em;
}*/


.logos { 
  display: flex;
  justify-content: space-evenly;
  padding-top: 50px;
}

.App-link {
  color: #f1a20d;
}

@keyframes Logo-spinL2R {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes Logo-spinR2L {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
  }


.Cell-row {
    padding-top: 5px;    /* Ajustez cette valeur comme vous le souhaitez */
    padding-bottom: 5px; /* Ajustez cette valeur comme vous le souhaitez */
}


.table-head {
  font-weight: bold;
}


pre {
  margin: 0;
}
