/*
 #2EACB4 pale
 #2999a0 foncé
*/
.org-table {
    border-collapse: separate!important;
    border-spacing: 0!important
}

.org-table td {
    position: relative;
    vertical-align: top;
    padding: 0 0 50px;
    text-align: center
}

.org-node {
    position: relative;
    display: inline-block;
    margin: 0 1em;
    box-sizing: border-box
}

.org-node .org-container {
    position: relative;
    display: inline-block;
    z-index: 2;
    width: 100%;
    min-width: 150px
}

.org-title {
    font-weight: 700;
    width: 100%;
    border: 1px solid #00000000;
    background-color: #2EACB4;
    border-radius: 4px;
    padding: 15px 5px;
    white-space: pre
}

.org-content {
    margin-top: 3px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
    white-space: pre;
    text-align: left;
    background-color: white;
}

.org-content .org-content-item {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 5px;
    border-bottom: 1px solid #e7e7e7;
    color: #2999a0;
}

.org-content .org-content-item:last-child {
    border-bottom: none
}

.org-content-item .item-box {
    flex: 1
}

.org-content-item .item-box .item-title {
    margin: 0;
    font-size: 1rem;
    font-weight: 600
}

.org-content-item .item-box .item-add {
    margin: 0;
    font-size: .8rem
}

.org-content-item .avat {
    display: block;
    width: 3em;
    height: 3em;
    overflow: hidden;
    background: #fff;
    border: 1px solid #ccc;
    box-sizing: border-box;
    border-radius: 2em;
    margin-left: 5px
}

.org-content-item .avat img {
    width: 100%;
    height: 100%
}

.org-child-level:before {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 100%;
    height: 15px;
    border-left: 2px solid #ccc;
    transform: translate3d(-1px,0,0)
}

.org-child-level:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: -15px;
    border-top: 2px solid #ccc
}

.org-child-level:first-child:before,.org-child-level:last-child:before {
    display: none
}

.org-child-level:first-child:after {
    left: 50%;
    height: 13px;
    border: 2px solid;
    border-color: #ccc transparent transparent #ccc;
    transform: translate3d(1px,0,0)
}

.org-child-level:last-child:after {
    right: 50%;
    height: 13px;
    border: 2px solid;
    border-color: #ccc #ccc transparent transparent;
    transform: translate3d(-1px,0,0)
}

.org-child-level:first-child.org-child-level:last-child:after {
    left: auto;
    border-radius: 0;
    border-color: transparent #ccc transparent transparent;
    transform: translate3d(1px,0,0)
}

.org-extend .org-extend-arrow:before {
    transform: rotate(-45deg)
}

.org-extend:after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 13px;
    height: 17px;
    border-left: 2px solid #ccc
}

.org-extend-arrow {
    position: absolute;
    left: 50.2%;
    bottom: 22px;
    width: 10px;
    height: 10px;
    padding: 10px;
    transform: translate3d(-15px,0,0);
    cursor: pointer
}

.org-extend-arrow:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    padding: 5px;
    box-sizing: border-box;
    border: 2px solid;
    border-color: #ccc #ccc transparent transparent;
    transform: rotate(135deg);
    transform-origin: -10% 50% 0;
    transition: transform ease .3s
}

.org-extend-arrow:hover:before {
    border-color: #333 #333 transparent transparent
}

/*
#root {
    max-width: 1280px;
    margin: 0 auto;
    padding: 2rem
}
*/
.foot {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #0074a6;
    padding: 24px;
    overflow: hidden;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    z-index: 999
}

.foot a {
    color: #fff;
    margin: 0 .5em
}

.backend-title {
    background-color: #66abd3;
    color: #fff
}

.backend-content {
    text-align: center
}

.frontend-title {
    background-color: #333;
    color: #fff
}

.frontend-content {
    text-align: center
}
