.grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);  /* Adjust for desired columns */
    grid-auto-rows: minmax(auto, 1fr);
    margin: 0!important;
    padding-left: 5px;
    padding-right: 10px;
}

.grid-item {
    display: grid;
    align-items: stretch;
    border-width: 1px;
    border: 0px;

    border: 1px solid lightgray;
    /*background-color: rgb(236, 234, 234);
    padding: 1rem;*/
    
}


.header-grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);  /* Adjust for desired columns */
    grid-auto-rows: minmax(auto, 1fr);
    
    /*border: 0;*/
    text-align: 'left';
    font-weight: 'light';
    font-size: 'small';

    padding-left: 2rem;
    padding-bottom: 1rem;
 /*
    
    padding-bottom: 0.5rem;

    background-position: left top;
    background-repeat: repeat-x;
    background-color: rgb(236, 234, 234);
    border: 1px solid white;
    color: black;*/
}
.header-grid-logo {
    padding-top: 0.5rem;
    width: 100%;
    max-height: 150;
}


.header-grid-item {
    display: grid;
    align-items: flex-start;
}

h4 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}